import HInfo from "../Common/HInfo";
import { useSelector } from "react-redux";
import { RootStore } from "../../Libs/Redux";
import { Outlet } from "react-router-dom";

export default function LyInfo() {
  let student = useSelector((state: RootStore) => state.student);

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <div className="flex-g">
      <HInfo username={student.info.username} sid={student.info.muId} />
      <Outlet />
    </div>
  );
}
