import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import HTable, { IHeadTabel } from "../../Common/HTable";

import StudentAction from "../../../Libs/Redux/Actions/Student.action";
import { IReport } from "../../../Libs/Models/IReport.model";

const headCells: IHeadTabel[] = [
  { id: "sid", label: "รหัส", align: "center", sort: false, width: 80 },
  {
    id: "username",
    label: "ชื่อ-นามสกุล",
    align: "center",
    sort: false,
    width: 300,
  },
  {
    id: "pretest",
    label: "PRE TEST",
    align: "center",
    sort: false,
    width: 120,
  },
  {
    id: "situation1",
    label: "SITUATION#1",
    align: "center",
    sort: false,
    width: 80,
  },
  {
    id: "situation2",
    label: "SITUATION#2",
    align: "center",
    sort: false,
    width: 80,
  },
  {
    id: "situation3",
    label: "SITUATION#3",
    align: "center",
    sort: false,
    width: 80,
  },
  {
    id: "posttest",
    label: "POST TEST",
    align: "center",
    sort: false,
    width: 120,
  },
  { id: "report", label: "REPORT", align: "center", sort: false, width: 100 },
  { id: "end", label: "", align: "center", sort: false, minWidth: 10 },
];

interface IProps {
  data: IReport[];
}

export function TableList(props: IProps) {
  /* const [order, setOrder] = useState<OrderType>(OrderType.Asc)
  const [orderBy, setOrderBy] = useState<keyof IStudent>('sid') */

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { situation } = useParams();

  //--------------------------------------------------//
  //--------------------------------------------------//
  const onClick = (value: IReport) => {
    dispatch(StudentAction.getStudentReports(value)); //--> .info
    if (situation) {
      navigate(`/${situation}/` + value.id);
    } else {
      navigate(`/situation1/` + value.id);
    }
  };

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" sx={{ width: '100%' }} aria-label="customized table">
        <HTable cells={headCells} />
        <TableBody>
          {props.data.map((row: IReport) => (
            <TableRow key={row.id}>
              <TableCell align="right">{row.muId}</TableCell>
              <TableCell align="center">{row.username}</TableCell>
              <TableCell align="center">
                {`${row.scorePre} (${row.nPre})`}
              </TableCell>
              <TableCell align="center">
                {`${row.scoreSit1} (${row.nSit1})`}
              </TableCell>
              <TableCell align="center">
                {`${row.scoreSit2} (${row.nSit2})`}
              </TableCell>
              <TableCell align="center">
                {`${row.scoreSit3} (${row.nSit3})`}
              </TableCell>
              <TableCell align="center">
                {`${row.scorePost} (${row.nPost})`}
              </TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  onClick={() => { onClick(row); }}
                >
                  <SystemUpdateAltIcon fontSize="small" />
                </Button>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
