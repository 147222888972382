import React from "react";
import { secondary } from "../../Styles/theme";
import { Box, Toolbar, Typography } from "@mui/material";

type State = {
  sid: string;
  username: string;
};

export default function HInfo({ username, sid }: State) {
  return (
    <div id="header-bar">
      <Toolbar variant="dense" />
      <Box sx={{ backgroundColor: secondary, p: 2 }}>
        <Typography variant="h5" sx={{ pb: 1 }} color={"#000"}>
          ข้อมูลนักศึกษา
        </Typography>
        <Typography variant="body2" sx={{ pb: 0 }}>
          {`${username}`}
          <br />
          {`${sid}`}
        </Typography>
      </Box>
    </div>
  );
}
