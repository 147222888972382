import { combineReducers, Store } from "redux";
import { persistStore, persistReducer } from "redux-persist";

import thunk from "redux-thunk";
//import logger from 'redux-logger';
import storage from "redux-persist/lib/storage";

import LoaderReducer from "./Reducers/Loader.reducer";
import StudentReducer from "./Reducers/Student.reducer";
//import UserProfileReducer from "./Reducers/userProfile.reducer";
//import EmployeeReducer from "./Reducers/employee.reducer";

import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["loaderState"],
};

let rootReducer = combineReducers({
  loader: LoaderReducer,
  student: StudentReducer,
  //userProfile: UserProfileReducer,
  //employeeDetail: EmployeeReducer,
});

let persistedReducer = persistReducer(persistConfig, rootReducer);

//const middlewareList = [thunk, logger]

const createReduxStore = () => {
  const store: Store<any, any> = configureStore({
    reducer: persistedReducer,
    //devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
  });

  const persistor = persistStore(store);
  return { store, persistor };
};
export default createReduxStore;
export type RootStore = ReturnType<typeof rootReducer>;
