import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    bTxt: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bTxt?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bTxt: true
  }
}
//73BBEE
export const primary = '#3FA2F7' //blue main
export const secondary = '#DEF6FF' //blue light
export const font_black = '#8E8E8E'
//export const font_black = '#5B5B5B'
//export const font2 = '#3FA2F7'

export const Theme = createTheme({
  palette: {
    /* action: {
      active: primary,
      focus: primary,
      hover: primary,
      selected: primary,
    }, */

    primary: {
      main: primary,
      //light: '#42a5f5',
      dark: '#2CB6F7',
      contrastText: '#fff',
    },
    secondary: {
      main: font_black,
    },
    info: { main: '#fff' },
    error: { main: '#F93535' },
    success: { main: '#238E2A' },
    /* warning: {},
    info: {},*/
    /* text: {
      primary: "rgba(0,0,0,0.87)"
    } */
  },
  typography: {
    fontFamily: [
      'MyriadPro',
      'THSarabun',
      'THSarabunBold',
      'Open Sans',
      'sans-serif',
      '-apple-system',
    ].join(','),
    /* bTxt: {
      fontFamily: 'RobotoMedium',
      color: '#fff',
    }, */

    h4: {
      fontFamily: 'THSarabunBold',
    },
    h5: {
      //TH //1.5rem //24px
      fontFamily: 'THSarabunBold',
      //lineHeight: 1.2,
      color: font_black,
    },
    h6: {
      //EN
      fontSize: '1.125rem', //18px
      letterSpacing: '0.0075em',
      lineHeight: 1.6,
      //color:font_black
    },
    /* h6: {},
    subtitle1: {},
    subtitle2: {}, */
    body1: {
      //Silderbar -EN
    },
    body2: {
      //TABLE - TH
      fontFamily: 'THSarabunBold',
      fontSize: '1.3125rem', //21px
      lineHeight: 1.1,
      color: font_black
    },
    button: {
      fontFamily: 'RobotoMedium',
      fontSize: '1rem',
      color: '#fff',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        style: {
          backgroundColor: 'white',
          borderTop: 'hidden',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        style: {
          backgroundColor: 'white',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          /*padding: '10px !important',
          '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment': {
            right: 2, // probably the width of your search IconButton or more if needed
            top: 0,
          },*/
          fontFamily: 'THSarabunBold',
          fontSize: '1.3125rem', //24px //h6
          color: font_black,
        },

        listbox: {
          fontFamily: 'THSarabunBold',
          fontSize: '1.3125rem', //24px //h6
          color: font_black,
          padding: 2,
          borderRadius: 10,
        },

        option: {
          '&:hover': {
            color: 'white',
            backgroundColor: '#3FA2F7 !important',
          },
          '&[aria-selected="true"]': {
            color: 'white',
            backgroundColor: '#3FA2F7 !important',
          },
        },
      },
    },
    ////// Table /////
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#fff',
          borderBottomColor: primary,
        },
        body: {
          fontFamily: 'THSarabunBold',
          fontSize: '1.3125rem', //21px //body2
          color: font_black,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderBottomColor: primary,
          '&:nth-of-type(odd)': {
            backgroundColor: '#F4F4F4',
          },
          /* // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        }, */
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        textPrimary: {
          color: font_black,
        },
      },
    },
  },
})
//theme = responsiveFontSizes(theme);
