export enum UserRole {
  /* ADMIN = "admin",
  CHIEFEDITOR = "chiefeditor",
  EDITOR = "editor",
  USER = "user", */
  A = 'all access',   //All access
  B = 'admin',
  C = 'tester',
  D = 'student',
  S = 'developer',    //developer
}

export interface IProfile {
  id: number;
  muId: string;
  email: string;
  username: string;
  //password?: string;
  //accessToken: string;
  fname: string;
  lname: string;
  year?: string;
  date_created?: string;
  date_updated?: string;
  role?: string;
  //profileImage?: string;
}


export function DProfile(): IProfile {
  return {
    //userId: '',
    id: 0,
    muId: '',
    email: '',
    username: '',
    //accessToken: '',
    fname: '',
    lname: '',
    year: '',
    role: '',
    //profileImage: '',
  }
}