import ActionType from "../@types/ActionType";

class StudentAction {
  /* getSituation = (data: any) => ({
    //Slidebar
    type: ActionType.GET_SITUATION,
    payload: data,
  }); */

  getStudentReports = (data: any) => ({
    //StudentList
    type: ActionType.GET_STUDENT_REPORTS,
    payload: data,
  });

  setReport = (data: any) => ({
    //ReportList
    type: ActionType.SET_REPORT,
    payload: data,
  });
}

export default new StudentAction();
