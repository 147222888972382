export function getMenu(param: string | undefined) {
  switch (param) {
    case "posttest":
      return {
        label: "POST TEST",
        report: "posttest",
      };
    case "situation1":
      return {
        label: "SITUATION #1",
        report: "situation1",
      };
    case "situation2":
      return {
        label: "SITUATION #2",
        report: "situation2",
      };
    case "situation3":
      return {
        label: "SITUATION #3",
        report: "situation3",
      };
    case "pretest":
    default:
      return {
        label: "PRE TEST",
        report: "pretest",
      };
  }
}
