//import { ISituation, IStudent } from "../../Models/IStudent.model";
import ActionType from "../@types/ActionType";
import IAction from "../@types/IAction";
import { IReport, ISituation } from "../../Models/IReport.model";

interface IStudentState {
  info: IReport;
  situation: ISituation[];
  sit_index: number;
}

const initialState = {} as IStudentState;

const reducer = (
  state: IStudentState = initialState,
  action: IAction<any>
): IStudentState => {
  switch (action.type) {
    /* case ActionType.GET_SITUATION: //Slidebar
      var stn_id = action.payload;
      if (stn_id === undefined) {
        stn_id = 0;
      }
      return { ...state, stn_id: action.payload }; */

    case ActionType.GET_STUDENT_REPORTS: //StudentList
      return { ...state, info: action.payload };

    case ActionType.SET_REPORT: //ReportList
      return {
        ...state,
        situation: action.payload.situlation,
        sit_index: action.payload.index,
      };
  }
  return state;
};

export default reducer;
