import {
  Autocomplete,
  Box,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useEffect, useState } from "react";
import { TableList } from "./table";
import { PPagination } from "../../Common/PPagination";

import { primary, secondary } from "../../../Styles/theme";
import ReportsService from "../../../Libs/Services/Reports.service";
import { IReport } from "../../../Libs/Models/IReport.model";

interface IProps {
  logoutCallback?: () => void;
}

export function StudentList(props: IProps) {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [curPage, setCurPage] = useState(1);
  const [student, setStudent] = useState<IReport[]>([]);
  const [dataShow, setDataShow] = useState<IReport[]>([]);
  const [year, setYear] = useState<string[]>(["ทั้งหมด"]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //All IReport
  async function fetchData() {
    ReportsService.findReports()
      .then((res) => {
        setStudent(res);
        setDataShow(res);

        var _y_ = Array.from(new Set(res.map(({ year }) => year)));
        _y_.sort();
        _y_.reverse();
        _y_.unshift("ทั้งหมด");
        setYear(_y_);
        setCurPage(1);
      })
      .catch((e) => {
        props.logoutCallback && props.logoutCallback();
      });
  }
  //--------------------------------------------------//
  //--------------------------------------------------//
  ///// Year /////
  const handleChageYear = (e: React.SyntheticEvent, value: string) => {
    if (value === "ทั้งหมด") {
      setDataShow(student);
    } else {
      var filter = student.filter((row: any) => row.year === value);
      setDataShow(filter);
    }
    setCurPage(1);
  };

  ///// Table /////
  const handleChangeRowsPerPage = (value: string) => {
    setRowsPerPage(Number(value));
    setCurPage(1);
  };

  const handleChangePage = (value: number) => {
    setCurPage(value);
  };

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <div className="flex-g">
      <Toolbar variant="dense" />
      <div id="header-bar">
        <Box sx={{ backgroundColor: secondary, p: 2 }}>
          <Typography variant="h5" sx={{ pb: 1 }} color={"#000"}>
            ข้อมูลนักศึกษา
          </Typography>

          <Autocomplete
            id="year"
            options={year}
            sx={{ width: 275 }}
            renderInput={(params) => (
              <Paper sx={{ p: 1 }} elevation={0}>
                <TextField
                  {...params}
                  hiddenLabel
                  fullWidth
                  variant="standard"
                  sx={{
                    ".MuiAutocomplete-endAdornment": {
                      right: -8,
                      top: -10,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              </Paper>
            )}
            popupIcon={
              <ArrowDropDownIcon
                sx={{
                  backgroundColor: primary,
                  height: 60,
                  width: 43,
                  color: "#fff",
                  borderRadius: 1,
                }}
              />
            }
            onChange={handleChageYear}
            PaperComponent={CustomPaper}
            defaultValue={year[0]}
            disableClearable
            size="small"
          />
        </Box>
      </div>
      <div id="detail">
        <Box sx={{ px: 2, pb: 2 }}>
          <div className="dis-flex flex-row flex-m">
            <Typography variant="body2" color="primary" sx={{ py: 2, pr: 2 }}>
              {`ข้อมูลการทำแบบทดสอบ`}
            </Typography>
          </div>
          <TableList
            data={dataShow.slice(
              (curPage - 1) * rowsPerPage,
              (curPage - 1) * rowsPerPage + rowsPerPage
            )}
          />
          <div className="p-t-20">
            <PPagination
              quantity={dataShow.length}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChagePage={handleChangePage}
            />
          </div>
        </Box>
      </div>
    </div>
  );
}

const CustomPaper = (props: any) => {
  return <Paper elevation={2} {...props} sx={{ mt: 1, width: 275, ml: -1 }} />;
};
