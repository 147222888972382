import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootStore } from "../../../Libs/Redux";
import { getMenu } from "../../../Libs/Modules/Menu";
import { secondary } from "../../../Styles/theme";
import { TableList } from "./table";
import dayjs from "dayjs";

import ExcelReport from '../../../Libs/Utils/excel/report';

export function DetailList() {
  const student = useSelector((state: RootStore) => state.student);
  const { situation } = useParams();
  const menu = getMenu(situation);
  const [num, setNum] = useState(student.sit_index);

  //info => (in) IStudent > IReport > ISituation > IQuestion > IAnswer
  //situation => (in) ISituation > IQuestion > IAnswer
  const [state, setState] = useState({
    id: student.sit_index,
    min: 1,
    max: student.situation.length,
    day: new Date(student.situation[student.sit_index - 1].time_start),
    score: student.situation[student.sit_index - 1].score,
    data: student.situation[student.sit_index - 1].question,
  });

  //--------------------------------------------------//
  //--------------------------------------------------//
  const handleSubmit = (event: any) => {
    event.preventDefault();
    var n = Number(num);
    if (n > state.max) {
      n = state.max;
    } else if (n < state.min) {
      n = state.min;
    }
    setNum(n);
    setState({
      ...state,
      id: n,
      day: new Date(student.situation[n - 1].time_start),
      score: student.situation[n - 1].score,
      data: student.situation[n - 1].question,
    });
  };

  const onPrint = () => {
    ExcelReport.report(student.info, state, menu.label);
  };
  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <Box id="detail" sx={{ px: 2, pb: 2 }}>
      <div className="dis-flex flex-row flex-m m-t-10">
        <Typography variant="body2" color="primary" sx={{ pr: 2 }}>
          {`ข้อมูลการทำแบบทดสอบ`}
        </Typography>
        <Typography variant="h5" color="secondary">
          {menu.label}
        </Typography>
      </div>
      <div className="dis-flex flex-row flex-m m-b-10" onSubmit={handleSubmit}>
        <Typography variant="body2" color="primary">
          {`แสดงการทดสอบครั้งที่`}
        </Typography>
        <TextField
          hiddenLabel
          type="number"
          variant="filled"
          InputProps={{
            inputProps: { min: state.min, max: state.max },
            disableUnderline: true,
            //readOnly: true,
            style: {
              backgroundColor: secondary,
              width: 66,
              height: 40,
              color: "#8E8E8E",
            },
          }}
          value={num}
          onChange={(e) => setNum(Number(e.target.value))}
          sx={{ px: 2 }}
        />
        <Typography variant="h5" color="secondary" sx={{ pr: 2 }}>
          {`of ${state.max}`}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          sx={{ color: "#fff", px: 2 }}
          onClick={handleSubmit}
        >
          แสดงผล
        </Button>
        <Box sx={{ px: 2 }}>
          <Button variant="contained" onClick={() => onPrint()} /* disabled */>
            <PrintIcon sx={{ fontSize: 28 }} />
          </Button>
        </Box>  {/**/}
      </div>
      <div className="dis-flex flex-row flex-m m-t-10 m-b-10">
        <Typography variant="h5" noWrap>
          ครั้งที่&nbsp;{state.id}
        </Typography>
        <Typography variant="h5" noWrap ml={3}>
          วันที่&nbsp;
          {dayjs(state.day).format("DD/MM/YYYY")}
        </Typography>
        <Typography variant="h5" noWrap ml={3}>
          RESULT&nbsp;{state.score}&nbsp;POINTS
        </Typography>
      </div>
      <TableList data={state.data} />
    </Box>
  );
}
