enum ActionType {
  NO_CHANGE,
  DESTROY,

  //Loading
  LOADING_CHANGE,
  LOADING_SPINNER_CHANGE,

  LOG_IN,

  /* GET_USER_RPOFILE,
  GET_EMPLOYEE_DETAIL, */

  //GET_SITUATION,        //Slidebar
  GET_STUDENT_REPORTS, //StudentList
  SET_REPORT, //ReportList
}

export default ActionType;
