///// Align /////
export var alignHCenter = { horizontal: "center" };
export var alignHLeft = { horizontal: "left" };
export var alignHRight = { horizontal: "right" };

export var alignVTop = { vertical: "top" };
export var alignVMiddle = { vertical: "middle" };
export var alignVBottom = { vertical: "bottom" };

///// Border /////
export var borderL = { left: { style: "medium" }, color: { argb: "00000000" } };
export var borderR = {
  right: { style: "medium" },
  color: { argb: "00000000" },
};
export var borderT = { top: { style: "medium" }, color: { argb: "00000000" } };
export var borderB = {
  bottom: { style: "medium" },
  color: { argb: "00000000" },
};
export var borderLT = {
  left: { style: "medium" },
  top: { style: "medium" },
  color: { argb: "00000000" },
};
export var borderLR = {
  left: { style: "medium" },
  right: { style: "medium" },
  color: { argb: "00000000" },
};
export var borderLB = {
  left: { style: "medium" },
  Bottom: { style: "medium" },
  color: { argb: "00000000" },
};
export var borderRT = {
  right: { style: "medium" },
  top: { style: "medium" },
  color: { argb: "00000000" },
};
export var borderRB = {
  right: { style: "medium" },
  Bottom: { style: "medium" },
  color: { argb: "00000000" },
};
export var borderLRT = {
  left: { style: "medium" },
  right: { style: "medium" },
  top: { style: "medium" },
  color: { argb: "00000000" },
};
export var borderLRB = {
  left: { style: "medium" },
  right: { style: "medium" },
  bottom: { style: "medium" },
  color: { argb: "00000000" },
};

export var borderLTB = {
  left: { style: "medium" },
  top: { style: "medium" },
  bottom: { style: "medium" },
  color: { argb: "00000000" },
};
export var borderRTB = {
  right: { style: "medium" },
  top: { style: "medium" },
  bottom: { style: "medium" },
  color: { argb: "00000000" },
};
export var borderLRTB = {
  left: { style: "medium" },
  right: { style: "medium" },
  top: { style: "medium" },
  bottom: { style: "medium" },
  color: { argb: "00000000" },
};

/////  /////
