import React, { useState, useEffect } from "react";
import { Auth } from "./Auth";
import { StudentList, Situation, DetailList } from "./Pages/index";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./Layout";
import LyInfo from "./Layout/LyInfo";
import { AuthContext } from "../Libs/Contexts";
import UserProfilesService from "../Libs/Services/UserProfiles.service";

function App() {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    setIsLogin(UserProfilesService.isUserLogin());
  }, []);

  //--------------------------------------------------//
  const login = () => {
    setIsLogin(UserProfilesService.isUserLogin());
  };

  const logout = () => {
    UserProfilesService.logout();
    setIsLogin(UserProfilesService.isUserLogin());
  };

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <BrowserRouter basename={"/"}>
      <AuthContext.Provider value={isLogin}>
        <Routes>
          <Route
            path="/login"
            element={<Auth loginCallback={login} logoutCallback={logout} />}
          />
          <Route element={<Layout logoutCallback={logout} />}>
            <Route path="/" element={<StudentList logoutCallback={logout} />} />
            <Route element={<LyInfo />}>
              <Route
                path="/:situation/:userId"
                element={<Situation logoutCallback={logout} />}
              />
              <Route
                path="/:situation/:userId/report"
                element={<DetailList />}
              />
            </Route>
          </Route>
        </Routes>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
