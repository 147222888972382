import HTable, { IHeadTabel } from "../../Common/HTable";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { IQuestion, IAnswer } from "../../../Libs/Models/IReport.model";

const headCells: IHeadTabel[] = [
  {
    id: "id",
    label: "ข้อที่",
    align: "center",
    sort: false,
    width: 80,
  },
  {
    id: "qus",
    label: "",
    align: "center",
    sort: false,
    width: 80,
  },
  { id: "ans", label: "", align: "left", minWidth: 580 },
];

interface IProps {
  data: IQuestion[];
}

export function TableList(props: IProps) {
  const formAns = (ans: IAnswer, index: number) => {
    const arr_a = ans.a.split(",");
    const arr_r = ans.r.split(",");
    return (
      <div className="dis-flex flex-row" key={"t_" + index}>
        <Typography variant="body2" sx={{ fontStyle: "italic" }}>
          {`#${index + 1}`}&nbsp;&nbsp;&nbsp;
        </Typography>
        <div className="dis-flex flex-row">
          {arr_a.map((a: string, index: number) =>
            index === 0 ? (
              <div key={"d_" + index}>
                <Typography
                  variant="body2"
                  color={arr_r[index] === "true" ? "success.main" : "error"}
                >
                  {a}
                </Typography>
              </div>
            ) : (
              <div key={"d_" + index}>
                <Typography
                  variant="body2"
                  color={arr_r[index] === "true" ? "success.main" : "error"}
                >
                  &nbsp;/&nbsp;{a}
                </Typography>
              </div>
            )
          )}
        </div>
      </div>
    );
  };
  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" sx={{ width: "100%" }} aria-label="customized table">
        <HTable cells={headCells} />
        <TableBody>
          {props.data.map(
            (answer: IQuestion, index: number) =>
              answer && (
                <React.Fragment key={answer.qid}>
                  <TableRow key={"r1_" + answer.qid}>
                    <TableCell align="center">{answer.qid}</TableCell>
                    <TableCell align="left">คำถาม</TableCell>
                    <TableCell align="left">{answer.qus}</TableCell>
                  </TableRow>
                  <TableRow key={"r2_" + answer.qid}>
                    <TableCell align="center"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">
                      {answer.ans.map((ans: IAnswer, index: number) =>
                        formAns(ans, index)
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
          )} {/* */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
